<template>
  <v-dialog v-model="open" persistent>
    <v-card class="card">
      <v-card-title></v-card-title>
      <v-card-text>
        <div class="text-center">
          <ScratchCard
            :key="renderCount"
            :cardWidth="cardWidth"
            :cardHeight="cardHeight"
            :finishPercent="finishPercent"
            text="請刮我"
            :forceReveal="forceReveal"
            @complete="onHandleComplete"
            class="mx-auto"
          >
            <div class="scratch-card">
              <div class="scratch-card__content card-content">
                <h2 class="">{{ padNumber(result.number) }}</h2>
                <template v-if="result.reward">
                  <span>恭喜獲得</span>
                  <p class="mt-2 mb-0">{{ result.reward || "" }}</p>
                </template>
                <template v-else>
                  <span>恭喜獲得</span>
                  <p class="mt-2 mb-0">
                    安慰獎點數 {{ ichiban.consolationPrize }}
                  </p>
                </template>
                <div class="image-box">
                  <img v-if="result.reward" :src="images.win" alt="恭喜中獎" />
                  <img v-else :src="images.lose" alt="安慰獎" />
                </div>
              </div>
            </div>
          </ScratchCard>
          <div class="mx-auto mt-4">
            <v-btn
              @click="reveal"
              rounded
              depressed
              :disabled="hasRevealed"
              color="#0e3959"
              class="white--text"
            >
              一鍵開獎
            </v-btn>
            <v-fade-transition>
              <v-btn
                @click="$emit('next')"
                v-if="hasNext"
                leave-absolute
                rounded
                depressed
                class="ml-3"
                color="success"
                :disabled="!hasRevealed"
              >
                下一張
              </v-btn>
              <v-btn
                @click="
                  () => {
                    $emit('complete');
                    open = false;
                  }
                "
                v-else-if="hasRevealed"
                leave-absolute
                rounded
                depressed
                class="ml-3"
                color="primary"
              >
                完成
              </v-btn>
            </v-fade-transition>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { Howl } from "howler";
import ScratchCard from "@/components/dashboard/ichiban/ScratchCard.vue";
// import winImage from "@/assets/win.jpg";
// import loseImage from "@/assets/lose.jpg";

export default {
  name: "DialogScratchCard",
  components: { ScratchCard },
  props: ["value", "result", "ichiban", "hasNext"],
  //"result":{"number":"5","reward":"1","position":9}
  data: () => ({
    renderCount: 0,
    cardWidth: 240,
    cardHeight: 300,
    finishPercent: 75,
    forceReveal: false,
    hasRevealed: false,
    // winImage,
    // loseImage,
    showStatusImage: false,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    verified() {
      return this.payload.address && this.payload.branch;
    },
    ...mapState("clientModule", {
      images: (state) => state.images,
    }),
  },
  methods: {
    reveal() {
      this.forceReveal = true;
      this.hasRevealed = true;
      this.playSoundEffect();
    },
    onHandleComplete() {
      this.hasRevealed = true;
      this.playSoundEffect();
    },
    padNumber(num) {
      return num.toString().padStart(3, "0");
    },
    playSoundEffect() {
      if (this.result.reward) {
        const sound = new Howl({
          src: ["/assets/sound/win.wav"],
        });
        sound.play();
      } else {
        const random = _.random(1, 3);
        const sound = new Howl({
          src: [`/assets/sound/fail/${random}.mp3`],
        });
        sound.play();
      }
    },
  },
  watch: {
    hasRevealed(val) {
      if (val) {
        this.showStatusImage = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: white;
}

.scratch-card {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 5px solid black;
  border-radius: 3px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;

    h2 {
      font-size: 72px;
      font-weight: 600;
      line-height: 1.2em;
      letter-spacing: 10px;
    }

    span {
      font-size: 20px;
    }
    p {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.image-box {
  width: 200px;
  height: auto;
  max-height: 115px;
  margin: 5px auto 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
