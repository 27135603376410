<template>
  <IchibanTemplate>
    <template v-slot:body>
      <div v-if="ichiban">
        <div v-if="!isSoldout" class="fixed-tools">
          <Timer
            v-if="isLock()"
            :time="ichiban.lockTo"
            :key="ichiban.lockTo"
            :description="
              ichiban.lockToUser === userID
                ? '一番賞為您保留中...'
                : '一番賞鎖定中，請等候...'
            "
          />
          <div class="fixed-tools__buttons">
            <v-btn depressed color="#111" class="button" @click="reload(true)">
              <v-icon color="#2fd5d9">mdi-autorenew</v-icon>
              刷新
            </v-btn>
            <v-btn depressed color="#111" class="button" @click="confirm">
              <v-icon color="#2fd5d9">mdi-clover</v-icon> 開始抽獎
            </v-btn>
          </div>
        </div>
        <div>
          <v-row align="center">
            <v-col cols="12" align-self="center">
              <div class="text-center mx-auto card">
                <v-carousel
                  :height="'300px'"
                  :show-arrows="false"
                  :interval="3000"
                  cycle
                  hide-delimiter-background
                >
                  <v-carousel-item
                    v-for="(item, i) in ichiban.contentImageUrls"
                    :key="i"
                    :src="item"
                  ></v-carousel-item>
                </v-carousel>
                <div class="pt-3 d-flex justify-space-between">
                  <div>{{ ichiban.name }}</div>
                  <div class="yellow--text">
                    <span class="vertical-middle">
                      （{{ ichiban.count }} 抽）
                    </span>
                    <v-img
                      width="15px"
                      height="15px"
                      src="@/assets/image/coin.png"
                      alt="點數"
                      contain
                      class="vertical-middle d-inline-block"
                    />
                    <span class="vertical-middle">
                      {{
                        ichiban.plans.find((plan) => plan.count === 1).amount
                      }}
                    </span>
                  </div>
                </div>
                <div class="mt-3">
                  <v-simple-table dark>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">號碼</th>
                          <th class="text-left">獎項</th>
                          <template v-if="isSoldout">
                            <th class="text-left">得獎者</th>
                            <th class="text-left">抽出時間</th>
                          </template>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="isSoldout">
                          <tr v-for="item in winRecords" :key="item.number">
                            <td :class="`text-left`">
                              {{ item.rewards.number }}
                            </td>
                            <td :class="`text-left`">
                              {{ item.rewards.reward }}
                            </td>
                            <td :class="`text-left`">
                              {{ item.userID }}
                            </td>
                            <td :class="`text-left`">
                              {{ item.createdAt }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" class="text-right pa-4">
                              中獎率
                              {{ `${ichiban.rewards.length}/${ichiban.count}` }}
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr
                            v-for="item in ichiban.rewards"
                            :key="item.number"
                          >
                            <td
                              :class="`text-left ${
                                item.hit ? 'line-through' : ''
                              }`"
                            >
                              {{ item.number }}
                            </td>
                            <td
                              :class="`text-left ${
                                item.hit ? 'line-through' : ''
                              }`"
                            >
                              {{ item.reward }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="text-right pa-4">
                              中獎率
                              {{
                                `  ${remainReward.length}/${
                                  ichiban.count - ichiban.sold
                                }`
                              }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!isSoldout" class="pa-2">
            <v-col
              class="pa-2"
              v-for="(number, n) in ichiban.count"
              :key="n"
              cols="4"
              sm="3"
              md="2"
              lg="2"
            >
              <v-card
                elevation="4"
                :class="{
                  left: true,
                  win: records[n] && records[n].reward,
                  lose: records[n] && !records[n].reward,
                  selected: positions.includes(n),
                }"
                @click="selectPosition(n)"
              >
                <div class="overlay" v-if="records[n]">
                  <p class="overlay__msg">
                    {{ records[n].reward ? "中獎" : "抽出" }}
                  </p>
                </div>
                <v-responsive :aspect-ratio="2 / 1">
                  <v-card-text class="number-card">
                    {{ number }}
                  </v-card-text>
                </v-responsive>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <DialogScratchCard
        v-if="dialog.scratch"
        v-model="dialog.scratch"
        :result="result"
        @complete="handleComplete"
        :ichiban="ichiban"
        :hasNext="hasNext"
        @next="popPositions"
        :key="positions.length"
      />
      <DialogUserVerify
        v-if="dialog.verify"
        v-model="dialog.verify"
        :user="user"
        @load="checkUserState"
        :userHint="true"
      />
      <v-dialog
        v-if="dialog.delivery"
        v-model="dialog.delivery"
        max-width="300px"
      >
        <v-card>
          <v-card-title>恭喜中獎</v-card-title>
          <v-card-text>
            <div class="subtitle-2">
              請至
              <a href="/ichibanDelivery">中獎訂單</a>
              頁面填寫收件資料。
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                () => {
                  if (isSoldout) {
                    dialog.delivery = false;
                    $dialog.info({
                      persistent: false,
                      title: '',
                      text: `這個一番賞大獎已經抽走了，歡迎選擇其他一番賞。`,
                      showClose: false,
                      actions: {
                        true: {
                          text: '了解',
                          color: 'primary',
                        },
                      },
                    });
                  } else {
                    dialog.delivery = false;
                  }
                }
              "
              class="bt-text-large"
            >
              了解
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </IchibanTemplate>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import delay from "delay";
import liff from "@line/liff";
import util from "@/mixins/util";
import { mapState } from "vuex";
import IchibanTemplate from "./IchibanTemplate.vue";
import DialogScratchCard from "@/components/dashboard/ichiban/DialogScratchCard.vue";
import DialogUserVerify from "@/components/dashboard/users/DialogUserVerify.vue";
import Timer from "@/components/client/Timer.vue";

export default {
  name: "DialogIchiban",
  mixins: [util],
  props: [],
  components: {
    IchibanTemplate,
    DialogScratchCard,
    DialogUserVerify,
    Timer,
  },
  data: () => ({
    records: [],
    result: null,
    dialog: { scratch: false, delivery: false, verify: false },
    ichiban: null,
    record: null,
    positions: [],
    winRecords: [],
    results: [],
    hasNext: false,
  }),
  async created() {
    if (!this.ichibanId || !this.user) {
      return this.$router.push({
        name: "Ichiban",
        query: { store: "@695yimoc" },
      });
    }
    this.$vloading.show();
    await Promise.all([this.getUser(), this.getIchiban()]);

    if (this.ichiban) {
      await this.getRecords();
    } else {
      this.$router.go(-1);
    }
    setTimeout(() => {
      this.$vloading.hide();
    }, 500);
  },
  computed: {
    _: _,
    getAmount() {
      let finded = _.find(this.ichiban.plans, { count: 1 });
      return finded ? finded.amount : null;
    },
    ichibanId() {
      return this.$route.params.id;
    },
    remainReward() {
      return this.ichiban.rewards.filter((o) => !o.hit);
    },
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
      images: (state) => state.images,
    }),
    isSoldout() {
      const isSoldout = this.ichiban.sold === this.ichiban.count;
      const isOutofReward = this.ichiban.rewards.every(
        (reward) => !!reward.hit
      );
      return isSoldout || isOutofReward;
    },
  },
  methods: {
    isLock() {
      if (!this.ichiban?.lockTo) {
        return false;
      }
      return !dayjs().isAfter(this.ichiban.lockTo);
    },
    async getRecords() {
      let { data } = await this.axios.get(`/ichibanRecord`, {
        params: { ichibanId: this.ichiban._id },
      });
      this.winRecords = data.filter((record) => !!record.rewards.reward);

      let rewords = _.mapKeys(
        data.map((o) => o.rewards),
        "position"
      );
      this.records = rewords;
    },
    async getIchiban() {
      try {
        const { data } = await this.axios.get(`/ichiban/${this.ichibanId}`);
        this.ichiban = data;
      } catch (error) {
        this.$router.go(-1);
      }
    },
    async checkUserState() {
      await this.getUser();

      if (!this.user.isVerified) {
        this.dialog.verify = true;
      }
    },
    async getUser() {
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getUser`, payload);

        this.$store.commit("clientModule/setState", {
          user: data,
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
    async selectPosition(n) {
      if (!this.user.isVerified) {
        this.dialog.verify = true;
        return;
      }
      if (_.find(this.records, { position: n })) {
        return;
      }
      if (this.positions.includes(n)) {
        return (this.positions = this.positions.filter(
          (position) => position !== n
        ));
      }
      if (this.positions.length >= 10) {
        return this.$dialog.confirm({
          persistent: false,
          showClose: false,
          title: "",
          text: `<p class="text-h6">最多只能選擇 10 個號碼</p>`,
          actions: {
            true: {
              text: "確認",
              color: "primary",
            },
          },
        });
      }
      return this.positions.push(n);
    },
    async confirm() {
      if (this.isLock() && this.ichiban.lockToUser !== this.user.userID) {
        await this.$dialog.info({
          persistent: false,
          title: "說明",
          text: `這個一番賞正在鎖定中，請等候...`,
          showClose: false,
          actions: {
            true: {
              text: "了解",
              color: "primary",
            },
          },
        });
        return;
      }

      if (this.positions.length === 0) {
        return this.$dialog.confirm({
          persistent: false,
          showClose: false,
          title: "",
          text: `<p class="text-h6">請先選擇號碼</p>`,
          actions: {
            true: {
              text: "確認",
              color: "primary",
            },
          },
        });
      }

      const price = this.getAmount * this.positions.length;
      const check = await this.$dialog.confirm({
        persistent: true,
        title: "",
        text: `<p class="text-h6">
          <span class="">連續花費 ${this.getAmount} 點抽取 ${this.positions
          .map((index) => index + 1)
          .join(", ")} 號籤？</span></p>`,
        actions: {
          false: {
            text: "取消",
            color: "error",
          },
          true: {
            text: "確認",
            color: "primary",
          },
        },
      });
      if (!check) return;
      await this.popPositions();
      this.openDialog("scratch");
    },
    async popPositions() {
      const position = this.positions.shift();

      if (!position && position !== 0) {
        this.closeDialog("scratch");
        return;
      }

      if (this.positions.length) {
        this.hasNext = true;
      } else {
        this.hasNext = false;
      }
      await this.callLottery(position);
    },
    async callLottery(n) {
      try {
        this.$vloading.show();
        let { data } = await this.axios.post(`/ichiban/call`, {
          ichiban: this.ichiban._id,
          position: n,
          user: this.user.userID,
        });
        await delay(800);
        this.$vloading.hide();
        // setTimeout(() => {
        //   this.$forceUpdate();
        // }, 500);
        if (!data.success) {
          throw data.message;
        }
        this.$store.commit("clientModule/setState", {
          user: data.user,
        });

        this.result = data.result;
        this.results.push(data.result);
        this.ichiban = data.ichiban;

        if (this.isSoldout) {
          this.positions = [];
          this.hasNext = false;
        }
        this.openDialog("scratch");
      } catch (error) {
        alert(error);
        this.positions = [];
        this.hasNext = false;
        await this.reload();
        this.closeDialog("scratch");

        const hasWin = this.results.some((item) => !!item.reward);
        if (hasWin) {
          this.dialog.delivery = true;
        }
        this.results = [];
      }
      this.$vloading.hide();
    },
    async handleComplete() {
      this.$vloading.show();
      await this.getRecords();
      await this.getIchiban();
      this.$vloading.hide();

      const hasWin = this.results.some((item) => !!item.reward);
      if (hasWin) {
        this.dialog.delivery = true;
      }
      this.results = [];
    },
    async reload(checkSoldout) {
      this.$vloading.show();
      await Promise.all([this.getRecords(), this.getIchiban()]);
      this.$vloading.hide();

      if (checkSoldout && this.isSoldout) {
        await this.$dialog.info({
          persistent: false,
          title: "",
          text: `這個一番賞大獎已經抽走了，歡迎選擇其他一番賞。`,
          showClose: false,
          actions: {
            true: {
              text: "確認",
              color: "primary",
            },
          },
        });
      }
    },
    closeLiff() {
      liff.closeWindow();
    },
    openDialog(type) {
      this.dialog[type] = true;
    },
    closeDialog(type) {
      this.dialog[type] = false;
    },
  },
};
</script>
<style lang="scss">
.v-btn.v-size--default {
  font-size: 16px !important;
}
// .theme--light.v-icon:focus::after {
//   opacity: 0;
// }
</style>
<style lang="scss" scoped>
.line-through {
  text-decoration: line-through;
}

.fixed-tools {
  width: fit-content;
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 1px;
  z-index: 2;
  margin: 0 auto;

  &__buttons {
    display: flex;

    > * {
      flex-grow: 1;
      color: #2fd5d9;
      outline: 1px solid #2fd5d9;
      border-radius: 0px;
    }
  }
}

.card {
  max-width: 500px;
  padding: 10px;
  margin: 5px;
  color: white;
  background-color: #d2e7fa1a;
  font-weight: 500;
  font-size: 20px;

  background-color: #172c3b;
}

.v-card {
  left {
    background-color: white;
    color: #000;
  }

  &.win {
    // background-color: #d4af37;
    background-color: #2fd5d8;
    color: #222;
  }
  &.lose {
    background-color: #ccc;
    color: #222;
  }

  &.selected {
    background-color: purple;
    color: white;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &__msg {
    color: white;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
  }
}

.number-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 33px;
}
</style>
