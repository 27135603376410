<template>
  <div ref="timer" class="timer">
    <v-icon color="#2fd5d9" class="mr-2"> mdi-lock </v-icon>
    <!-- Years:
    <div data-years></div>
    Days:
    <div data-days></div>
    Hours:
    <div data-hours></div> -->
    <span data-minutes></span>
    <span>：</span>
    <span data-seconds></span>
    <p v-if="description" class="text-center">{{ description }}</p>
  </div>
</template>

<script>
import timezz from "timezz";

export default {
  name: "Timer",
  props: ["time", "description"],
  data: () => ({
    instance: null,
  }),
  computed: {},
  methods: {},
  created() {},
  mounted() {
    this.instance = timezz(this.$refs.timer, {
      date: this.time,
      update: (event) => {
        if (event.isTimeOver) {
          this.$emit("timeover");
        }
      },
    });
  },

  beforeUnmount() {
    this.instance.destroy();
  },
};
</script>

<style lang="scss" scoped>
.timer {
  width: 100%;
  padding: 10px 20px;
  background-color: #111;
  outline: 1px solid #2fd5d9;
  text-align: center;

  span {
    color: #2fd5d9;
    vertical-align: middle;
    font-size: 28px;
  }
  p {
    color: #2fd5d9;
    vertical-align: middle;
    font-size: 18px;
    margin: 0 0;
  }
}
</style>
