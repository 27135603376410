<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card ref="form">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-alert color="#2fd5d8" v-if="userHint">
          請先完成會員資料，就可以開始抽取線上一番賞。
        </v-alert>
        <div class="mb-2">
          <v-text-field
            ref="name"
            label="名稱"
            outlined
            dense
            v-model="payload.name"
            validate-on-blur
            :rules="[rules.required]"
          ></v-text-field>
        </div>
        <div class="mb-2">
          <v-text-field
            ref="phone"
            v-model="payload.phone"
            label="電話號碼"
            outlined
            dense
            clearable
            validate-on-blur
            :rules="[rules.required, rules.phone]"
          ></v-text-field>
        </div>
        <div class="mb-2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="birth"
                v-model="payload.birth"
                label="生日"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                validate-on-blur
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="payload.birth"
              no-title
              scrollable
              locale="zh-tw"
              :max="today"
              @input="menu = false"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <div class="mb-2">
          <v-alert color="#2fd5d8" v-if="userHint">
            將預設為獎品寄送地址，請確認填寫正確。
          </v-alert>
          <v-text-field
            ref="address"
            v-model="payload.address"
            label="地址"
            outlined
            dense
            clearable
            validate-on-blur
            :rules="[rules.required]"
          ></v-text-field>
        </div>
        <!-- <div class="mb-2" v-if="userHint">
          <v-checkbox
            v-model="agree"
            label="是否同意相關條款（待補）"
            dense
            outlined
          ></v-checkbox>
        </div> -->
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DialogUserVerify",
  props: ["value", "user", "userHint"],
  components: {},
  data: () => ({
    today: dayjs().format("YYYY-MM-DD"),
    payload: {
      name: "",
      phone: "",
      birth: "",
      address: "",
    },
    menu: false,
    agree: true,
    rules: {
      required: (value) => !!value || "必填欄位.",
      phone: (value) => {
        const taiwanPhoneRegex = /^09\d{8}$/;
        return taiwanPhoneRegex.test(value) || "電話號碼格式錯誤";
      },
    },
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    if (this.user) {
      const user = this.user;
      this.payload.name = user.name;
      this.payload.phone = user.phone;
      this.payload.address = user.address;
      this.payload.birth = user.birth;
    }
  },
  methods: {
    async confirm() {
      let hasError = false;
      Object.keys(this.payload).forEach((f) => {
        this.$refs[f].validate(true);
        if (this.$refs[f].errorBucket.length > 0) {
          hasError = true;
        }
      });

      if (hasError) return;
      try {
        await this.axios.post(`/users/update-information/${this.user.userID}`, {
          ...this.payload,
        });

        this.$toast.success("更新完成");
        this.$emit("load");
        this.open = false;
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },
  },
};
</script>

<style></style>
